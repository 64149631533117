import { Locale } from '@/types'
import {
  BuildingFull,
  CockpitBuildingOperation,
  CockpitBuildingsMarkersOperation,
  CockpitBuildingsOperation
} from '@/services/types'
import { createLocalizableTag } from '@/utils/i18n'
import { urlWithQueryParams } from '@/utils/url'
import { cockpitFetch } from '../client'

const getBuildings = async ({
  locale,
  type,
  category,
  finality,
  state,
  city,
  neighborhood,
  display_in_home,
  random,
  ignore,
  limit = 4,
  cache = true
}: {
  locale: Locale
  type?: string
  category?: string
  finality?: string
  state?: string
  city?: string
  neighborhood?: string
  display_in_home?: boolean
  random?: boolean
  ignore?: string
  limit?: number
  cache?: boolean
}): Promise<CockpitBuildingsOperation> => {
  const res = await cockpitFetch<CockpitBuildingsOperation>({
    resource: urlWithQueryParams(category ? `buildings` : `buildings`, {
      locale,
      type,
      category,
      finality,
      state,
      city,
      neighborhood,
      display_in_home,
      random,
      ignore,
      limit
    }),
    ...(!cache && { cache: 'no-store' }),
    ...(cache && {
      tags: [
        createLocalizableTag(
          `buildings-${Object.values({
            type,
            category,
            finality,
            state,
            city,
            neighborhood,
            display_in_home,
            limit
          }).join('-')}`,
          locale
        )
      ]
    })
  })

  return res?.body
}

const getBuilding = async ({
  locale,
  slug
}: {
  locale: Locale
  slug: string
}): Promise<BuildingFull | null> => {
  try {
    const res = await cockpitFetch<CockpitBuildingOperation>({
      resource: urlWithQueryParams(`buildings/${slug}`, { locale }),
      tags: [createLocalizableTag(`buildings-${slug}`, locale)]
    })

    return res?.body?.data
  } catch (error) {
    return null
  }
}

const getFavoritesBuildings = async ({
  locale,
  properties
}: {
  locale: Locale
  properties: string[]
}): Promise<CockpitBuildingsOperation> => {
  const res = await cockpitFetch<CockpitBuildingsOperation>({
    resource: urlWithQueryParams('favorites', { locale, properties }),
    cache: 'no-store'
  })

  return res?.body
}

const getBuildingMarkers = async ({
  locale
}: {
  locale: Locale
}): Promise<CockpitBuildingsMarkersOperation> => {
  const res = await cockpitFetch<CockpitBuildingsMarkersOperation>({
    resource: urlWithQueryParams('buildings/markers', { locale }),
    revalidate: 43200
  })

  return res?.body
}

const getBuildingSlug = async ({
  reference
}: {
  reference: string
}): Promise<{ slug: string } | null> => {
  try {
    const res = await cockpitFetch<{ slug: string }>({
      method: 'GET',
      resource: `building/${reference}`,
      tags: [`building-${reference}`],
      revalidate: 86400
    })

    return res?.body
  } catch (error) {
    return null
  }
}

export {
  getBuildings,
  getBuilding,
  getFavoritesBuildings,
  getBuildingMarkers,
  getBuildingSlug
}
